






















































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import { CategoryFilter } from '@/domain/category/model/CategoryFilter';
import { DisplayCategory } from '@/domain/category/display/model/DisplayCategory';
import DisplayCategoryPerDepthList from '@/domain/category/display/views/DisplayCategoryPerDepthList.vue';
import { DisplayCategoryService } from '@/domain/category/display/service/DisplayCategoryService';
import { apolloClient } from '@/apolloClient';
import { StandardCategory } from '@/common/model/Category';
import DisplayCategoryMapping from '@/domain/category/display/components/modal/DisplayCategoryMapping.vue';
import _ from 'lodash';
import { CommonState } from '@frientrip/domain';

const displayCategoryService = new DisplayCategoryService(apolloClient);

interface CategoriesInfo {
  categories?: DisplayCategory[];
  parentId?: string;
  depth: number;
  parentCategoryName?: string;
}

export default Vue.extend({
  name: 'DisplayCategoryList',
  components: {
    DisplayCategoryMapping,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DisplayCategoryPerDepthList,
  },
  data() {
    return {
      childCategories: [],

      categories: [] as DisplayCategory[],
      firstCategoriesInfo: {} as CategoriesInfo,
      secondCategoriesInfo: {} as CategoriesInfo,
      thirdCategoriesInfo: {} as CategoriesInfo,
      fourthCategoriesInfo: {} as CategoriesInfo,
      page: this.$route.query.page || 1,
      pageSize: this.$route.query.pageSize || 100,
      filter: {
        ids: null,
        depth: 0,
        parentId: null,
        likeLabelName: null,
      } as CategoryFilter,
      relatedStandards: [] as StandardCategory[],
      selectedDisplayCategory: {
        children: [],
        depth: 0,
        label: {
          name: '',
        },
      },
      showDisplayCategoryModal: false,
      selectDepth: 0,
      selectDisplayCategory: [
        {
          depth: 0,
        },
        {
          depth: 1,
        },
        {
          depth: 2,
        },
        {
          depth: 3,
        },
      ] as CategoriesInfo[],
    };
  },
  computed: {
    showMappingButton(): boolean {
      console.log(this.selectedDisplayCategory);
      return (
        (this.selectedDisplayCategory.children?.length === 0 ||
          this.selectedDisplayCategory.depth === 3) &&
        this.selectedDisplayCategory.label.name !== ''
      );
    },
  },
  watch: {
    $route: 'getCategories',
  },
  async mounted() {
    await this.getCategories();
  },
  methods: {
    addChildren(category: DisplayCategory) {
      const depth = category.depth + 1;

      this.$data.selectedDisplayCategory = category;

      if (depth > 3) {
        return;
      }

      this.selectDisplayCategory[depth] = {
        depth: depth,
        parentId: category.id,
        categories: category.children,
        parentCategoryName: category.label.name,
      };

      this.selectDepth = category.depth;

      this.selectDisplayCategory.map((row: CategoriesInfo, index) => {
        if (row.depth > depth) {
          this.selectDisplayCategory[row.depth] = {
            depth: index,
          };
        }
      });

      if (category.relatedStandards?.length > 0) {
        this.relatedStandards = category.relatedStandards;
      } else {
        this.relatedStandards = [];
      }
    },
    async getCategories(): Promise<void> {
      const categoryConnection =
        await displayCategoryService.getDisplayCategories({
          page: Number(this.page),
          size: Number(this.pageSize),
          filter: this.filter,
        });

      categoryConnection.edges.map(edge => {
        if (edge.node.depth == 0) {
          this.categories.push(edge.node);
        }
      });

      this.categories.sort((currentDisplayCategory, nextDisplayCategory) => {
        return currentDisplayCategory.seqNo - nextDisplayCategory.seqNo;
      });

      this.selectDisplayCategory[0] = {
        depth: 0,
        categories: this.categories,
      };

      this.$forceUpdate();
    },
    async changeSeqNo(categoryInfo: CategoriesInfo): Promise<void> {
      const depth = categoryInfo.depth;
      this.selectDisplayCategory[depth] = categoryInfo;

      if (categoryInfo.depth === 0) {
        this.categories = categoryInfo.categories!;
        return;
      }
    },
    showDisplayMappingModal(): void {
      this.$data.showDisplayCategoryModal = true;
    },
    close(): void {
      this.$data.showDisplayCategoryModal = false;
    },
    changeStatus(category: DisplayCategory): void {
      const status = category.status;

      const changeCategory = this.selectDisplayCategory[
        category.depth
      ].categories!.find((row: DisplayCategory) => {
        return category.id === row.id;
      });

      if (changeCategory?.children) {
        changeCategory.children.map((row: DisplayCategory) => {
          let cloneChildren: DisplayCategory[] = [];
          let visitedChildren: DisplayCategory[] = [];
          row.status = status;
          cloneChildren = _.toArray(row.children);

          while (cloneChildren.length > 0) {
            const visit: DisplayCategory | undefined = cloneChildren.shift();

            if (!visit) {
              return;
            }

            visit.status = status;

            if (!visitedChildren.includes(visit)) {
              visitedChildren.push(visit);
              if (visit.children) {
                cloneChildren = [...cloneChildren, ...visit.children];
              }
            }
          }
        });
      }
    },
    async addCategory(category: DisplayCategory): Promise<void> {
      if (category) {
        this.selectDisplayCategory[category.depth].categories?.push(category);
      }

      this.$forceUpdate();
    },
    async checkCategoryStatus(
      parentId: string,
      depth: number,
      isActivateFunc: (x: boolean) => boolean
    ): Promise<void> {
      if (!this.selectDisplayCategory[depth - 1].categories) {
        isActivateFunc(false);
      }

      const parentCategory = this.selectDisplayCategory[
        depth - 1
      ].categories!.find((displayCategory: DisplayCategory) => {
        return displayCategory.id === parentId;
      });

      isActivateFunc(parentCategory!.status === CommonState.ACTIVE);
    },
  },
  // apollo: {
  //   getCategories: {
  //     query: DISPLAY_CATEGORY_LIST_QUERY,
  //     variables(): CategoryRequestPram {
  //       return {
  //         page: Number(this.page),
  //         size: Number(this.pageSize),
  //         filter: this.filter,
  //       };
  //     },
  //     error(e: ApolloError): void {
  //       console.error(e);
  //     },
  //     result(
  //       result: ApolloQueryResult<{
  //         category: { displays: DisplayCategoryConnection };
  //       }>
  //     ) {
  //       this.$data.categories = result.data.category.displays.edges.map(
  //         edge => edge.node as DisplayCategory
  //       );
  //     },
  //     update: data => data.category.displays,
  //   },
  // },
});
