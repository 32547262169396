






















































































import { CategoryLabel } from '@/domain/category/label/model/CategoryLabel';
import { CommonState } from '@frientrip/domain';
import DisplayCategoryCreate from '@/domain/category/display/views/DisplayCategoryCreate.vue';
import { DisplayCategory } from '../model/DisplayCategory';
import { DisplayCategoryService } from '../service/DisplayCategoryService';
import { apolloClient } from '@/apolloClient';
import draggable from 'vuedraggable';
import { ToggleButton } from 'vue-js-toggle-button';
import { StandardCategory } from '@/domain/category/standard/model/StandardCategory';
import { ContainerMixin } from '@/common/mixin/containerMixin';

const displayCategoryService = new DisplayCategoryService(apolloClient);

export default ContainerMixin.extend({
  name: 'DisplayCategoryPerDepthList',
  components: {
    DisplayCategoryCreate,
    draggable,
    ToggleButton,
  },
  props: {
    categoriesInfo: {
      type: Object,
    },
    depth: {
      type: Number,
    },
    selectDepth: {
      type: Number,
    },
  },
  data() {
    return {
      searchedCategoryLabels: [] as CategoryLabel[],
      selectedLabel: { id: '', code: '', name: '' } as CategoryLabel,
      showCategoryLabelSearchModal: false,
      showErrorMessageTime: 0,
      errorMessage: '',
      toggleButtonState: false,
      activeIndex: -1,
    };
  },
  updated() {
    if (this.depth !== this.selectDepth) {
      this.activeIndex = -1;
    }
  },
  computed: {
    categories: {
      get(): DisplayCategory[] {
        return this.categoriesInfo.categories;
      },
      set(value: DisplayCategory[]) {
        this.categoriesInfo.categories = value;
      },
    },
    parentId(): number {
      return this.categoriesInfo.parentId;
    },
  },
  methods: {
    setActive(index: number): void {
      this.activeIndex = index;
    },
    selectCategory(category: DisplayCategory, index: number): void {
      this.setActive(index);
      this.$emit('addChildren', category);
    },
    showModal(): void {
      console.log(this.categoriesInfo);
      this.showCategoryLabelSearchModal = true;
    },
    closeModal(displayCategory: DisplayCategory): void {
      this.showCategoryLabelSearchModal = false;
      this.$emit('addCategory', displayCategory);
    },
    selectLabel(categoryLabel: CategoryLabel) {
      this.selectedLabel = categoryLabel;
    },
    searchLabels(categoryLabels: CategoryLabel[]) {
      this.searchedCategoryLabels = categoryLabels;
    },
    isActive(category: DisplayCategory): boolean {
      return category.status == CommonState.ACTIVE;
    },
    async activateCategory(category: DisplayCategory): Promise<void> {
      await displayCategoryService.activateDisplayCategory(Number(category.id));
      category.status = CommonState.ACTIVE;
      this.toggleButtonState = false;
      // await this.$router.go(0);
    },

    async inactivateCategory(category: DisplayCategory): Promise<void> {
      await displayCategoryService.inactivateDisplayCategory(
        Number(category.id)
      );
      category.status = CommonState.INACTIVE;
      this.toggleButtonState = false;
      // await this.$router.go(0);
    },
    async changeStatusCategory(
      category: StandardCategory
    ): Promise<void | boolean> {
      console.log(this.depth);
      if (this.depth !== 0) {
        let isActivate = true;
        await this.$emit(
          'checkCategoryStatus',
          this.parentId,
          this.depth,
          (x: boolean) => {
            return (isActivate = x);
          }
        );

        if (!isActivate) {
          this.showErrorAlert({ message: '상위 카테고리를 활성화 해주세요.' });
          return false;
        }
      }
      this.toggleButtonState = true;
      category.status === CommonState.ACTIVE
        ? await (this as any).inactivateCategory(category)
        : await (this as any).activateCategory(category);
      this.$emit('changeStatus', category);
    },
    async moveCategory(event: any): Promise<void> {
      const oldIndex = event.oldIndex;
      const newIndex = event.newIndex;
      console.log('oldIndex :', oldIndex);
      console.log('newIndex :', newIndex);

      try {
        const movedOldCategory = this.categoriesInfo.categories.find(
          (displayCategory: DisplayCategory) => {
            return displayCategory.seqNo === newIndex;
          }
        );

        const movedCategory = this.categoriesInfo.categories.find(
          (i: DisplayCategory) => i.seqNo == oldIndex
        ) as DisplayCategory;

        await displayCategoryService.moveDisplayCategory({
          id: Number(movedCategory.id),
          seqNo: newIndex,
        });

        movedCategory.seqNo = newIndex;
        movedOldCategory.seqNo = oldIndex;

        this.categories.sort(
          (beforeRow: DisplayCategory, afterRow: DisplayCategory) => {
            return beforeRow.seqNo - afterRow.seqNo;
          }
        );
        this.$forceUpdate();
        this.$emit('changeSeqNo', this.categoriesInfo);
      } catch (err) {
        this.showErrorAlert(err, '카테고리 이동 실패!');
      }

      // await this.$router.go(0);
    },
  },
});
