

























































































































import { apolloClient } from '@/apolloClient';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
  StandardCategoryAPIResponse,
  StandardCategoryConnection,
  StandardCategoryEdge,
} from '@/domain/category/standard/model/StandardCategory';
import { Table, TableColumn } from 'element-ui';
import { StandardCategory } from '@/common/model/Category';
import { DisplayCategoryService } from '@/domain/category/display/service/DisplayCategoryService';
import { CategoryMapParam } from '@/domain/category/display/model/DisplayCategoryGraphqltype';

import { STANDARD_LEAVES } from '@/components/Category/queries/query';
import { LeafCategoryFilter } from '@/domain/category/model/LeafCategoryFilter';
import { ContainerMixin } from '@/common/mixin/containerMixin';

interface StandardLeavesParam {
  page: number;
  size: number;
  filter: LeafCategoryFilter;
}

const displayCategoryService = new DisplayCategoryService(apolloClient);

export default ContainerMixin.extend({
  name: 'DisplayCategoryMapping',
  props: {
    displayCategories: {
      type: Array,
    },
    depth: {
      type: Number,
    },
    displayCategory: {
      type: Object,
    },
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      allStandardCategories: [],
      loading: true,
      standardCategoryFilter: {
        likeLabelNameIncludePath: '',
      },
      page: 1,
      size: 10,
      totalCount: 0,
      checkedCategoryList: [],
    };
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
    pageClick(bvEvent: any, page: number): void {
      this.$data.page = page;
    },
    searchCategoryLabel(): void {
      this.$apollo.queries.categories.refetch();
    },
    addList(
      event: any,
      standardCategory: StandardCategory & { checked?: boolean }
    ): void {
      standardCategory.checked = event;
      if (event) {
        this.$data.checkedCategoryList.push(standardCategory);
      } else {
        this.removeFromCheckedList(standardCategory);
      }
    },
    removeFromCheckedList(
      checkedStandardCategory: StandardCategory & { checked?: boolean }
    ): void {
      const index = this.$data.checkedCategoryList.findIndex(
        (standardCategory: StandardCategory) => {
          return standardCategory.id === checkedStandardCategory.id;
        }
      );

      this.$data.checkedCategoryList.splice(index, 1);
      checkedStandardCategory.checked = false;
    },
    async mappingCategory(): Promise<void> {
      try {
        this.$modal.show(
          {
            message:
              '변경 시, 해당 표준 카테고리에 맵핑된 모든 상품의 전시 카테고리 정보가 일괄적으로 변경됩니다. 계속하시겠습니까?',
            showCancelButton: true,
            type: 'danger',
            confirmText: '변경하기',
            cancelText: '취소',
          },
          async () => {
            await Promise.all(
              this.$data.checkedCategoryList.map(
                async (category: StandardCategory) => {
                  const param: CategoryMapParam = {
                    displayCategoryId: Number(this.displayCategory.id),
                    standardCategoryId: category.id.toString(),
                  };

                  await displayCategoryService.connectBetweenCategory(param);
                }
              )
            );
            this.$notify({
              title: '표준 카테고리 매핑 성공',
              message: '매핑 성공',
              type: 'success',
            });
            this.$emit('refresh');
            (this as any).closeModal();
          }
        );
      } catch (err) {
        this.showErrorAlert(err, '표준 카테고리 매핑 실패');
      }
    },
    disabledCheckBox(
      standardCategory: StandardCategory & { checked?: boolean }
    ): boolean | void {
      if (this.displayCategories.length === 0) {
        return;
      }

      return (
        this.displayCategories.findIndex((category: any) => {
          return category.id === standardCategory.id;
        }) !== -1
      );
    },
  },
  apollo: {
    categories: {
      query: STANDARD_LEAVES,
      variables(): StandardLeavesParam {
        return {
          page: Number(this.$data.page),
          size: Number(this.$data.size),
          filter: this.$data.standardCategoryFilter,
        };
      },

      error(e: ApolloError): void {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          category: { standardLeaves: StandardCategoryConnection };
        }>
      ) {
        this.$data.allStandardCategories =
          result.data.category.standardLeaves.edges.map(
            (edge: StandardCategoryEdge) => {
              let node: StandardCategoryAPIResponse | undefined = edge.node;
              let path = [];
              let categoryIds = [];

              const checkStatus =
                this.$data.checkedCategoryList.findIndex(
                  (checkedCategory: { id: string }) => {
                    return checkedCategory.id === edge.node.id;
                  }
                ) !== -1;

              while (node) {
                path.unshift(node.label.name);
                categoryIds.unshift(node.id);
                node = node.parent;
              }

              return {
                id: edge.node.id,
                name: path.join(' > '),
                paths: path,
                checked: checkStatus,
              };
            }
          );
        this.$data.totalCount = result.data.category.standardLeaves.totalCount;
      },
      update: data => data.category.categories,
    },
  },
});
