


































































































































import Vue from 'vue';
import { CategoryLabel } from '@/domain/category/label/model/CategoryLabel';
import CategoryLabelCreate from '@/domain/category/label/views/CategoryLabelCreate.vue';
import { apolloClient } from '@/apolloClient';
import { DisplayCategoryService } from '@/domain/category/display/service/DisplayCategoryService';
import { DISPLAY_LEAVES } from '@/components/Category/queries/query';
import { ApolloQueryResult } from 'apollo-client';
import { LeafCategoryFilter } from '@/domain/category/model/LeafCategoryFilter';
import {
  DisplayCategoryConnection,
  DisplayCategoryEdge,
} from '@/domain/category/display/model/DisplayCategoryGraphqltype';
import { DisplayCategory } from '@/domain/category/display/model/DisplayCategory';
import { CategoryType } from '@/domain/category/model/CategoryType';

interface StandardLeavesParam {
  page: number;
  size: number;
  filter: LeafCategoryFilter;
}

const displayCategoryService = new DisplayCategoryService(apolloClient);

export default Vue.extend({
  name: 'DisplayCategoryCreate',
  components: {
    CategoryLabelCreate,
  },
  props: {
    parentCategoryId: {
      type: String,
    },
    categoryName: {
      type: String,
    },
    depth: {
      type: Number,
    },
  },
  data() {
    return {
      isSelected: false,
      areaUnrelated: false,
      searchedCategoryLabelName: '',
      selectedCategoryLabel: { id: '', code: '', name: '' } as CategoryLabel,
      searchedCategoryLabels: [] as CategoryLabel[],
      showErrorMessageTime: 0,
      errorMessage: '',
      parentCategoriesInfo: [],
      page: 1,
      size: 20,
      currentParentCategories: [],
      categoryType: CategoryType.DISPLAY,
    };
  },
  computed: {
    isExposureCategoryLabelCreateForm(): boolean {
      return (
        this.searchedCategoryLabelName.length > 0 &&
        this.searchedCategoryLabels.length == 0 &&
        this.selectedCategoryLabel.id == ''
      );
    },
    searchCategoryVisualArea(): { display: string } {
      if (this.searchedCategoryLabelName.length === 0) {
        return { display: 'none' };
      } else {
        return { display: 'block' };
      }
    },
  },
  created() {
    console.log(this.categoryName);
  },
  methods: {
    searchCategoryLabelName(inputName: string): void {
      this.searchedCategoryLabelName = inputName;

      if (this.searchedCategoryLabelName.length === 0) {
        this.searchedCategoryLabels = [];
        this.selectedCategoryLabel = {
          id: '',
          code: '',
          name: '',
        };
      }
    },
    selectCategoryLabel(categoryLabel: CategoryLabel): void {
      this.selectedCategoryLabel = categoryLabel;
      this.isSelected = true;
    },
    deSelectCategoryLabel(): void {
      this.selectedCategoryLabel = {
        id: '',
        code: '',
        name: '',
      };
    },
    searchCategoryLabels(categoryLabels: CategoryLabel[]) {
      this.searchedCategoryLabels = categoryLabels;
    },
    countDownChanged(showErrorMessageTime: number): void {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    async createCategory(): Promise<void> {
      try {
        const category = await displayCategoryService.createDisplayCategory({
          parentId: Number(this.parentCategoryId),
          categoryLabelCode: this.selectedCategoryLabel.code,
          areaUnrelated: this.areaUnrelated,
        });

        this.$notify({
          title: '카테고리 등록 성공',
          message: '카테고리가 등록되었습니다.',
          type: 'success',
          position: 'bottom-right',
        });

        this.$emit('closeModal', category);
        // await this.$router.go(0);
      } catch (e) {
        console.error(e);
        this.$notify({
          title: '카테고리 등록 실패',
          message: e.message.replace('GraphQL error:', ''),
          type: 'warning',
          position: 'bottom-right',
        });
        throw e;
      }
    },
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    closeModal(displayCategory: DisplayCategory): void {
      this.$emit('closeModal', displayCategory);
    },
  },
  apollo: {
    currentParentCategories: {
      query: DISPLAY_LEAVES,
      variables(): StandardLeavesParam {
        return {
          page: this.page,
          size: this.size,
          filter: {
            likeLabelNameIncludePath: this.categoryName,
          },
        };
      },
      debounce: 200,
      result(
        result: ApolloQueryResult<{
          category: { displayLeaves: DisplayCategoryConnection };
        }>
      ) {
        const categories = result.data.category.displayLeaves.edges
          .map((edge: DisplayCategoryEdge) => {
            let category: DisplayCategory | undefined = edge.node;
            let path = [];
            let categoryIds = [];

            while (category) {
              path.unshift(category.label.name);
              categoryIds.unshift(category.id);
              category = category.parent;
            }

            return {
              id: edge.node.id,
              path: path.join(' > '),
              paths: path,
              ids: categoryIds,
            };
          })
          .filter(category => {
            return category.ids.includes(this.parentCategoryId);
          });

        const currentCategoryInfo = [];
        for (let i = 0; i < this.depth; i++) {
          const info = {
            depth: i + 1,
            categoryName: categories[0].paths[i],
            id: categories[0].ids[i],
          };

          currentCategoryInfo.push(info);
        }

        this.$data.parentCategoriesInfo = currentCategoryInfo;
      },
      update: data => data.category.displayLeaves,
      skip(): boolean {
        return this.categoryName === '';
      },
    },
  },
});
